import styles from './styles.module.css';
import classNames from 'classnames';
import {
	ContentfulCollapsiblePanelSection,
	Maybe,
	ContentfulProgramsAndServicesPage,
	ContentfulProjectResource,
	ContentfulPublicationResource,
	ContentfulReportResource,
	ContentfulArticleResource,
	ContentfulVideoResource,
} from 'src/graphql-types';
import {
	PrivateCollapsiblePanelSection,
	RelevantPages,
	FilterContentTypeItems,
	defaultProgramsAndServicesPageSections,
	PageNames,
	AccessibleByValues,
	defaultServicesPageTabsSections,
	getHrImageUrl,
} from 'src/utils/common';
import React, { useState, useEffect, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { DefaultLayoutTemplate } from 'src/pages/layout';
import { MainImage } from 'src/components/mainImage';
import {
	SectionContainer,
	SectionContainerTitle,
	SectionContainerGrid,
} from 'src/components/sectionContainer';
import { LinkHelper } from 'src/utils/link-helper';
import {
	getDefaultRichTextOptionsPrependId,
	getRichText,
} from 'src/templates/utils/richTextOptions/defaultOptions';
import { curatedTitle } from 'src/utils/curated-title';
import { ContentThumbnailCard } from 'src/components/contentThumbnailCard';
import {
	SectionWithSearchResourceTemplate,
	mapResources,
} from 'src/templates/sectionWithSearchResourceTemplate/sectionWithSearchTemplate';
import { CollapsiblePanelCollection } from 'src/templates/collapsiblePanel/collapsiblePanelCollection';
import { CustomButton } from 'src/components/formComponents/customButton';
import { MainTabPanel, MainTabItem } from 'src/components/mainTabPanel';
import { useLoginManager } from 'src/utils/login-manager';
import { useQuery } from '@apollo/react-hooks';
import InMarketContactsGraphql from './in-market-contact-us.graphql';

interface ModifiedContentfulProgramsAndServicesPage
	extends Omit<
		ContentfulProgramsAndServicesPage,
		| 'marketAccessCollapsiblePanels'
		| 'trainingTopSectionCollapsiblePanels'
		| 'trainingBottomSectionCollapsiblePanels'
		| 'inMarketServicesCollapsiblePanels'
	> {
	marketAccessCollapsiblePanels?: Maybe<
		ContentfulCollapsiblePanelSection | PrivateCollapsiblePanelSection
	>[];
	trainingTopSectionCollapsiblePanels?: Maybe<
		ContentfulCollapsiblePanelSection | PrivateCollapsiblePanelSection
	>[];
	trainingBottomSectionCollapsiblePanels?: Maybe<
		ContentfulCollapsiblePanelSection | PrivateCollapsiblePanelSection
	>[];
	inMarketServicesCollapsiblePanels?: Maybe<
		ContentfulCollapsiblePanelSection | PrivateCollapsiblePanelSection
	>[];
}

const internalLinks = {
	supplyChain: LinkHelper.parseInternalLink(defaultProgramsAndServicesPageSections.supplyChain),
	marketAccess: LinkHelper.parseInternalLink(defaultProgramsAndServicesPageSections.marketAccess),
	training: LinkHelper.parseInternalLink(defaultProgramsAndServicesPageSections.training),
	inMarketServices: LinkHelper.parseInternalLink(
		defaultProgramsAndServicesPageSections.inMarketServices
	),
};

interface ContactUsQueryResult {
	inMarketServicesContactUsCards: {
		contentful_id?: string;
		topDescription?: string;
		name?: string;
		email?: string;
		phone?: string;
	}[];
}

interface PageContext {
	pageContext: {
		data: ModifiedContentfulProgramsAndServicesPage;
		supplyChainResearchProjects: ContentfulProjectResource[];
		supplyChainPublications: ContentfulPublicationResource[];
		supplyChainReports: ContentfulReportResource[];
		supplyChainArticles: ContentfulArticleResource[];
		supplyChainVideos: ContentfulVideoResource[];
		trainingArticles: ContentfulArticleResource[];
		trainingProjects: ContentfulProjectResource[];
		trainingPublications: ContentfulPublicationResource[];
		trainingVideos: ContentfulVideoResource[];
		trainingReports: ContentfulReportResource[];
	};
}

const ProgramsAndServicesPageDetails = (props: PageContext) => {
	const loginManager = useLoginManager();
	const [activeTab, setActiveTab] = useState<string>(internalLinks.supplyChain);
	const {
		data,
		supplyChainResearchProjects,
		supplyChainPublications,
		supplyChainReports,
		supplyChainArticles,
		supplyChainVideos,
		trainingArticles,
		trainingProjects,
		trainingPublications,
		trainingVideos,
		trainingReports,
	} = props?.pageContext;

	if (!data) {
		console.error('No page data for this programsAndServicesPage');
		return (
			<DefaultLayoutTemplate>
				<SectionContainer withBorderTop>
					<p className={styles.message}>This page is under maintenance. Please try again later</p>
				</SectionContainer>
			</DefaultLayoutTemplate>
		);
	}

	useEffect(() => {
		// uri hash may have collapsible-panel substring on it
		const getTabFromHash = (tab: string) => {
			if (tab.startsWith(internalLinks.inMarketServices)) return internalLinks.inMarketServices;
			if (tab.startsWith(internalLinks.marketAccess)) return internalLinks.marketAccess;
			if (tab.startsWith(internalLinks.supplyChain)) return internalLinks.supplyChain;
			if (tab.startsWith(internalLinks.training)) return internalLinks.training;
			return activeTab;
		};

		const onHashChange = () => {
			if (window?.location?.hash && window.location.hash.replace) {
				const tab = getTabFromHash(window.location.hash.replace('#', ''));
				if (activeTab !== tab) {
					setActiveTab(tab);
				}
			} else {
				history.replaceState(null, document?.title, `#${activeTab}`);
			}
		};

		// Make sure to set the initial tab.
		onHashChange();

		if (typeof window !== 'undefined') {
			// And listen to future hash changes
			window.addEventListener('hashchange', onHashChange);
		}

		return function cleanup() {
			if (typeof window !== 'undefined') {
				window.removeEventListener('hashchange', onHashChange);
			}
		};
	});

	const {
		contentful_id,
		title,
		subtitle,
		titleBackgroundImage,
		supplyChainDescription,
		supplyChainDescriptionPublicationLink,
		supplyChainFeaturedResearchProjects,
		supplyChainFeaturedPublicationAndReports,
		marketAccessDescription,
		marketAccessCollapsiblePanels,
		marketAccessTradeInquiriesContent,
		trainingDescription,
		trainingTopSectionTitle,
		trainingTopSectionText,
		trainingTopSectionImage,
		trainingTopSectionCollapsiblePanels,
		trainingBottomSectionTitle,
		trainingBottomSectionText,
		trainingBottomSectionImage,
		trainingBottomSectionCollapsiblePanels,
		trainingOtherTrainingMaterials,
		inMarketServicesDescription,
		inMarketServicesCollapsiblePanels,
		supplyChainResearchProjectsHeading,
		supplyChainPublicationsAndReportsHeading,
		marketAccessTradeEnquiriesHeading,
		trainingOtherMaterialsHeading,
		inMarketServicesContactUsHeading,
	} = data;

	const { data: inMarketServicesContactsData, refetch } = useQuery<ContactUsQueryResult>(
		InMarketContactsGraphql,
		{
			variables: {
				id: contentful_id,
			},
		}
	);

	interface WrapperProps {
		children: ReactNode;
	}

	const SupplyChainWrapper = (props: WrapperProps) => {
		if (Boolean(supplyChainDescriptionPublicationLink)) {
			return (
				<SectionContainerGrid columns="2" className={styles.gridWithImageOnSide}>
					{props.children}
				</SectionContainerGrid>
			);
		} else {
			return <>{props.children}</>;
		}
	};

	interface SecureNode {
		accessibleBy?: string[];
	}

	const isNodePublic = (node: SecureNode | undefined) => {
		return Boolean(
			!node?.accessibleBy ||
				(node?.accessibleBy?.length &&
					node?.accessibleBy?.find((ab) => ab === AccessibleByValues.Public))
		);
	};

	const supplyChainContent = (
		<>
			<SectionContainer withBorderTop isGrey>
				<SupplyChainWrapper>
					<div>
						{getRichText(
							supplyChainDescription,
							getDefaultRichTextOptionsPrependId(internalLinks.supplyChain)
						)}
					</div>
					{supplyChainDescriptionPublicationLink && (
						<div>
							<ContentThumbnailCard
								innerWrapperClassName={styles.supplyChainDescriptionImage}
								linkTo={LinkHelper.linkTo(supplyChainDescriptionPublicationLink)}
								imageSrc={
									supplyChainDescriptionPublicationLink?.thumbnailLink?.thumbnailImage?.fluid?.src
								}
								downloadLink={
									isNodePublic(supplyChainDescriptionPublicationLink as SecureNode)
										? supplyChainDescriptionPublicationLink?.publicationFile?.file?.url
										: undefined
								}
							>
								<div>{supplyChainDescriptionPublicationLink?.thumbnailLink?.title}</div>
							</ContentThumbnailCard>
						</div>
					)}
				</SupplyChainWrapper>
			</SectionContainer>
			<SectionWithSearchResourceTemplate
				featuredResources={mapResources(supplyChainFeaturedResearchProjects)}
				publicRelevantResources={mapResources(supplyChainResearchProjects)}
				page={RelevantPages.PSSupplyChain}
				resourceTypes={[FilterContentTypeItems.Project]}
				title={
					supplyChainResearchProjectsHeading ||
					defaultServicesPageTabsSections.supplyChain.researchProjectsHeading
				}
				titleId={`${internalLinks.supplyChain}-${LinkHelper.parseInternalLink(
					supplyChainResearchProjectsHeading ||
						defaultServicesPageTabsSections.supplyChain.researchProjectsHeading
				)}`}
			/>
			<SectionWithSearchResourceTemplate
				featuredResources={mapResources(supplyChainFeaturedPublicationAndReports)}
				publicRelevantResources={mapResources([
					...(supplyChainPublications || []),
					...(supplyChainReports || []),
					...(supplyChainArticles || []),
					...(supplyChainVideos || []),
				])}
				page={RelevantPages.PSSupplyChain}
				resourceTypes={[
					FilterContentTypeItems.Publication,
					FilterContentTypeItems.Report,
					FilterContentTypeItems.Article,
					FilterContentTypeItems.Video,
				]}
				title={
					supplyChainPublicationsAndReportsHeading ||
					defaultServicesPageTabsSections.supplyChain.publicationsAndReportsHeading
				}
				titleId={`${internalLinks.supplyChain}-${LinkHelper.parseInternalLink(
					supplyChainPublicationsAndReportsHeading ||
						defaultServicesPageTabsSections.supplyChain.publicationsAndReportsHeading
				)}`}
			/>
		</>
	);

	const marketAccessContent = (
		<>
			<SectionContainer withBorderTop isGrey>
				{getRichText(
					marketAccessDescription,
					getDefaultRichTextOptionsPrependId(internalLinks.marketAccess)
				)}
			</SectionContainer>
			<SectionContainer>
				<CollapsiblePanelCollection
					parentId={internalLinks.marketAccess}
					panelsCollection={marketAccessCollapsiblePanels}
				/>
			</SectionContainer>
			{marketAccessTradeInquiriesContent && (
				<SectionContainer isGrey>
					<SectionContainerTitle
						text={
							marketAccessTradeEnquiriesHeading ||
							defaultServicesPageTabsSections.marketAccess.tradeEnquiriesHeading
						}
						withSeparator
						id={`${internalLinks.marketAccess}-${LinkHelper.parseInternalLink(
							marketAccessTradeEnquiriesHeading ||
								defaultServicesPageTabsSections.marketAccess.tradeEnquiriesHeading
						)}`}
					/>
					{getRichText(
						marketAccessTradeInquiriesContent,
						getDefaultRichTextOptionsPrependId(internalLinks.marketAccess)
					)}
					<CustomButton
						content="Make a Trade Enquiry"
						linkTo={`/${LinkHelper.getPageBaseUrl(
							PageNames.ContactUs
						)}#${LinkHelper.parseInternalLink('Trade enquiry')}`}
						variant="primary"
						className={styles.makeATradeEnquiryButton}
					/>
				</SectionContainer>
			)}
		</>
	);

	const trainingTopSectionUri = trainingTopSectionTitle
		? LinkHelper.parseInternalLink(trainingTopSectionTitle)
		: LinkHelper.parseInternalLink(defaultServicesPageTabsSections.training.topSection);

	const trainingBottomSectionUri = trainingBottomSectionTitle
		? LinkHelper.parseInternalLink(trainingBottomSectionTitle)
		: LinkHelper.parseInternalLink(defaultServicesPageTabsSections.training.bottomSection);

	const hasTopImage = trainingTopSectionImage?.file?.url;
	const topSectionColumns = hasTopImage ? '2' : '1';
	const topSectionClass = classNames({ [styles.gridWithImageOnSide]: hasTopImage });

	const hasBottomImage = trainingBottomSectionImage?.file?.url;
	const bottomSectionColumns = hasBottomImage ? '2' : '1';
	const bottomSectionClass = classNames({ [styles.gridWithImageOnSide]: hasBottomImage });

	const trainingOtherTrainingMaterialsResources = mapResources([
		...(trainingOtherTrainingMaterials || []),
		...(trainingArticles || []),
		...(trainingPublications || []),
		...(trainingVideos || []),
		...(trainingReports || []),
		...(trainingProjects || []),
	]);

	const trainingContent = (
		<>
			<SectionContainer isGrey withBorderTop>
				{getRichText(
					trainingDescription,
					getDefaultRichTextOptionsPrependId(internalLinks.training)
				)}
			</SectionContainer>
			<SectionContainer>
				<SectionContainerGrid columns={topSectionColumns} className={topSectionClass}>
					<div>
						<SectionContainerTitle
							text={trainingTopSectionTitle}
							id={`${internalLinks.training}-${trainingTopSectionUri}`}
						/>
						{getRichText(
							trainingTopSectionText,
							getDefaultRichTextOptionsPrependId(internalLinks.training)
						)}
					</div>
					{trainingTopSectionImage?.file?.url && (
						<div>
							<img
								className={styles.trainingSectionImageRight}
								src={trainingTopSectionImage.file.url}
							/>
						</div>
					)}
				</SectionContainerGrid>
				<CollapsiblePanelCollection
					parentId={`${internalLinks.training}-${trainingTopSectionUri}`}
					panelsCollection={trainingTopSectionCollapsiblePanels}
				/>
			</SectionContainer>
			<SectionContainer isGrey>
				<SectionContainerGrid columns={bottomSectionColumns} className={bottomSectionClass}>
					{trainingBottomSectionImage?.file?.url && (
						<div>
							<img
								className={styles.trainingSectionImageLeft}
								src={trainingBottomSectionImage.file.url}
							/>
						</div>
					)}
					<div className={styles.bottomSectionTextContainer}>
						<SectionContainerTitle
							text={trainingBottomSectionTitle}
							id={`${internalLinks.training}-${trainingBottomSectionUri}`}
						/>
						{getRichText(
							trainingBottomSectionText,
							getDefaultRichTextOptionsPrependId(internalLinks.training)
						)}
					</div>
				</SectionContainerGrid>
				<CollapsiblePanelCollection
					parentId={`${internalLinks.training}-${trainingBottomSectionUri}`}
					panelsCollection={trainingBottomSectionCollapsiblePanels}
				/>
			</SectionContainer>
			{trainingOtherTrainingMaterialsResources?.length && (
				<SectionWithSearchResourceTemplate
					featuredResources={trainingOtherTrainingMaterialsResources}
					page={RelevantPages.PSTraining}
					title={
						trainingOtherMaterialsHeading ||
						defaultServicesPageTabsSections.training.otherMaterialsHeading
					}
					titleId={`${internalLinks.training}-${LinkHelper.parseInternalLink(
						trainingOtherMaterialsHeading ||
							defaultServicesPageTabsSections.training.otherMaterialsHeading
					)}`}
				/>
			)}
		</>
	);

	const getInMarketContacts = () => {
		const inMarketContacts = inMarketServicesContactsData?.inMarketServicesContactUsCards;
		if (!inMarketContacts) {
			if (loginManager.isLoggedIn) {
				refetch();
			}
		}
		return (
			loginManager.isLoggedIn &&
			inMarketContacts?.length && (
				<SectionContainer isGrey>
					<SectionContainerTitle
						text={
							inMarketServicesContactUsHeading ||
							defaultServicesPageTabsSections.inMarketServices.contactUsHeading
						}
						withSeparator
						id={`${internalLinks.inMarketServices}-${LinkHelper.parseInternalLink(
							inMarketServicesContactUsHeading ||
								defaultServicesPageTabsSections.inMarketServices.contactUsHeading
						)}`}
					/>
					<SectionContainerGrid columns="2">
						{inMarketContacts
							.filter((card) => card)
							.map((card, i: number) => {
								return (
									<div className={styles.contactCardWrapper} key={`${card?.contentful_id}_${i}`}>
										{card?.topDescription && (
											<div>
												<p>{card.topDescription}</p>
											</div>
										)}
										<h6 className={styles.contactCardName}>{card?.name}</h6>
										<div>
											{card?.email && (
												<p>
													<a className={styles.contactCardLink} href={`mailto:${card.email}`}>
														{card.email}
													</a>
												</p>
											)}
											{card?.phone && (
												<p>
													<a className={styles.contactCardLink} href={`tel:${card.phone}`}>
														{card.phone}
													</a>
												</p>
											)}
										</div>
									</div>
								);
							})}
					</SectionContainerGrid>
				</SectionContainer>
			)
		);
	};

	const inMarketServicesContent = (
		<>
			<SectionContainer isGrey withBorderTop>
				{getRichText(
					inMarketServicesDescription,
					getDefaultRichTextOptionsPrependId(internalLinks.inMarketServices)
				)}
			</SectionContainer>
			<SectionContainer>
				<CollapsiblePanelCollection
					parentId={internalLinks.inMarketServices}
					panelsCollection={inMarketServicesCollapsiblePanels}
				/>
			</SectionContainer>
			{getInMarketContacts()}
		</>
	);

	const getTabContent = (tabIndex: string) => {
		switch (tabIndex) {
			case internalLinks.supplyChain:
				return supplyChainContent;
			case internalLinks.marketAccess:
				return marketAccessContent;
			case internalLinks.training:
				return trainingContent;
			case internalLinks.inMarketServices:
				return inMarketServicesContent;
			default:
				return undefined;
		}
	};

	const onTabClick = (tab: string) => {
		history.pushState(null, document.title, `#${tab}`);
		setActiveTab(tab);
	};

	const isTabActive = (tab: string) => {
		return activeTab === tab;
	};

	return (
		<DefaultLayoutTemplate>
			<Helmet>
				<title>{curatedTitle(title)}</title>
			</Helmet>
			<MainImage
				backgroundImageSrc={getHrImageUrl(titleBackgroundImage?.file?.url)}
				header={title}
				paragraph={subtitle}
			/>
			<MainTabPanel>
				<MainTabItem
					text={defaultProgramsAndServicesPageSections.supplyChain}
					id={LinkHelper.parseInternalLink(defaultProgramsAndServicesPageSections.supplyChain)}
					isActive={isTabActive(internalLinks.supplyChain)}
					onClick={() => onTabClick(internalLinks.supplyChain)}
				/>
				<MainTabItem
					text={defaultProgramsAndServicesPageSections.marketAccess}
					id={LinkHelper.parseInternalLink(defaultProgramsAndServicesPageSections.marketAccess)}
					isActive={isTabActive(internalLinks.marketAccess)}
					onClick={() => onTabClick(internalLinks.marketAccess)}
				/>
				<MainTabItem
					text={defaultProgramsAndServicesPageSections.training}
					id={LinkHelper.parseInternalLink(defaultProgramsAndServicesPageSections.training)}
					isActive={isTabActive(internalLinks.training)}
					onClick={() => onTabClick(internalLinks.training)}
				/>
				<MainTabItem
					text={defaultProgramsAndServicesPageSections.inMarketServices}
					id={LinkHelper.parseInternalLink(defaultProgramsAndServicesPageSections.inMarketServices)}
					isActive={isTabActive(internalLinks.inMarketServices)}
					onClick={() => onTabClick(internalLinks.inMarketServices)}
				/>
			</MainTabPanel>
			{getTabContent(activeTab)}
		</DefaultLayoutTemplate>
	);
};

export default class ProgramsAndServicesPage extends React.Component<PageContext> {
	render() {
		return <ProgramsAndServicesPageDetails {...this.props} />;
	}
}
